
<template>
  <section id="guide-media">
    <land-img
      :height="wdHeight"
      :src="cover"
      flat
      max-width="100%"
      tile
    >
      <v-container
        style="max-width: 1200px; height: 100%;"
      >
        <v-row
          class="ma-0 fill-height"
          align="center"
        >
          <v-col
            cols="12"
            md="7"
            sm="12"
          >
            <div class="co-flex-col">
              <span class="text-md-h5 text-h6 ml-4">{{ header.title }}</span>
              <span class="text-body-1 text-md-subtitle-1 ml-4 mt-4">{{ header.subtitle }}</span>
              <div class="co-flex-row mt-10">
                <div
                  v-for="(item, index) in medias"
                  :key="index"
                  class="co-flex-col co-justify-center co-items-center"
                  :class="[smAndDown ? 'ml-3' : 'mx-3']"
                >
                  <v-img
                    :src="item.qrcode"
                    ratio="1"
                    :width="smAndDown ? 70 : 130"
                  />
                  <span
                    class="mt-2"
                    :class="[smAndDown ? 'co-text-2xs' : 'co-text-sm']"
                  >{{ item.label }}</span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="hidden-sm-and-down"
          />
        </v-row>
      </v-container>
    </land-img>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  export default {
    name: 'SectionGuideMedia',
    props: {},
    data () {
      return {
        cover: '',
        header: {},
        medias: [],
        content: {},
        points: {
          xs: 400,
          sm: 500,
          md: 500,
          lg: 500,
          xl: 500
        },
      }
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || 500
        return ht
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
      this.getMedia()
    },
    methods: {
      getMedia () {
        const me = this
        let params = null
        const executed = function (res) {
          if (res.status) {
            const item = params.items[0] || {}
            me.content = item.content || {}
            me.cover = item.cover || ''
            me.header = me.content.header || {}
            me.medias = me.content.items || []
          }
        }
        params = web.banner.getParams({
          type: 'home-media',
          currentPage: '1',
          pageSize: '1',
          executed
        })

        api.httpx.getItems(params)
      }
    }
  }
</script>
